<template>
        <div class="view">
          <v-breadcrumbs>
            <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
              >Home</v-breadcrumbs-item
            >
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item :exact="true" :to="{ name: 'pageParameters', params: {} }">
            Page Parameters
            </v-breadcrumbs-item>
            <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
            <v-breadcrumbs-item :to="{ name: 'pageParametersView', params: {} }">
              View Page Parameters
            </v-breadcrumbs-item>
          </v-breadcrumbs>

          <v-card v-if="loaded">
            <v-card-title>
              View Page Parameters
            </v-card-title>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    
          <tr v-if="pageParameters.name">
            <td max-width="35%" class="text-subtitle-1">Name:</td>
            <td>{{ pageParameters.name }}</td>
          </tr>
          
          <tr v-if="pageParameters.page_name">
            <td max-width="35%" class="text-subtitle-1">Page Name:</td>
            <td>{{ pageParameters.page_name }}</td>
          </tr>
          
          <tr v-if="pageParameters.vuex_name">
            <td max-width="35%" class="text-subtitle-1">Vuex Name:</td>
            <td>{{ pageParameters.vuex_name }}</td>
          </tr>
          
          <tr v-if="pageParameters.owner_only">
            <td max-width="35%" class="text-subtitle-1">Owner Only:</td>
            <td>{{ pageParameters.owner_only }}</td>
          </tr>
          
          <tr v-if="pageParameters.created_by">
            <td max-width="35%" class="text-subtitle-1">Page Parameters:</td>
            <td>{{ pageParameters.created_by }}</td>
          </tr>
          
          <tr v-if="pageParameters.create_time">
            <td max-width="35%" class="text-subtitle-1">Created on:</td>
            <td>{{ pageParameters.create_time }}</td>
          </tr>
          
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <div class="d-flex justify-space-between ma-5">
            <v-btn @click="goBack">Back</v-btn>
            <v-btn @click="edit(id)" color="primary">Edit</v-btn>
            <span></span>
          </div>
      </div>
    </template>

    <script>
    import { getPageParameters } from "@/services/pageParameters";
    

    export default {
      data() {
        return {
            id: '',
            pageParameters: {},
            loaded: false,
            relations: {}
        }
      },
      methods : {
        goBack() {
          this.$router.go(-1);
        },
        edit(id) {
          this.$router.push({ name: "pageParametersEdit", params: { id: id }});
        },
        setInstance() {
          this.setDependencies().then( () => {
            getPageParameters(this.id)
            .then((response) => {
              this.pageParameters = response.data;
              this.loaded = true;
            })
            .catch( () => {
              this.addErrorMessage("Error loading pageParameters");
              this.loaded = true;
            });
          })
        },
        async setDependencies() {
          return null;
        },
        getRelationText(id, relationProperty, relationAttribute) {
          const relationList = this.relations[relationProperty];
          if (relationList) {
            const relationObj = relationList.find(r => r.id == id);
            return relationObj[relationAttribute];
          } else {
            return "";
          }
        }
      },
      created() {
        this.id = this.$route.params.id;
        this.setInstance();
        this.$emit("showParent", false);
      }
    }
    </script>
    